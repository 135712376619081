// spacers
$gap-s: 5px;
$gap-m: 10px;
$gap-l: 20px;

// sticky header height normal, no scrolling
$header-max-height: 100px;
// sticky header height by scrolling
$header-min-height: 60px;

// main content
$content-max-width: 960px;
$content-padding-x: 50px;

// footer
$footer-text-color: #b1c4d0;
$footer-bg-color: #15384f;

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #f2f5f7;
}

.teaser {
  margin-bottom: $gap-l;
}

section {
  &:not(:first-of-type) {
    margin-top: $gap-l;
  }

  .container {
    padding: $gap-l $gap-l * 1.5;
    background-color: #fff;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  > :not(:first-child) {
    margin-left: $gap-m;
  }

  &.center-x {
    align-items: center;
  }

  &.center-y {
    justify-content: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  > :not(:first-child) {
    margin-left: $gap-m;
  }

  &.center-x {
    justify-content: center;
  }

  &.center-y {
    align-items: center;
  }
}

.fit-max {
  > :first-child {
    flex-basis: content;
  }

  > :not(:first-child) {
    flex-grow: 1;
  }
}