/*
* Author: Łukasz Holeczek
* Template: Red Box Bootstrap Theme
* Version: 1.0.1
* Bootstrap version: 2.2.1
* Copyright 2012 creativeLabs
* www: http://clabs.co
* mail: lukasz@clabs.co
* You can buy this theme on WrapBootstrap: https://wrapbootstrap.com/theme/red-box-business-template-WB036830X
*/

/* Import Section
=================================================================== */

@import url("_icons_redshift.scss");
/* Icons. */

@import url("_icons_color.scss");
/* Icons. */

@import url("_social_icons.scss");
/* Social Icons */

// @import url("fancybox.css");
/* Fancybox */

// @import url("font-awesome.css");
/* Font Awesome*/

// @import url("font-awesome-ie7.css");
/* Font Awesome*/

.clear {
  clear: both;
}

html {
  position: relative;
}

// body {
//   padding-top: 10px;
// }

body>#wrapper {
  padding-top: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Droid Sans" !important; */
  font-weight: normal !important;
  color: #414141;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-size: 20px;
  line-height: 40px;
}

h3 {
  font-size: 18px;
  line-height: 32px;
}

h4 {
  font-size: 13px;
  line-height: 30px;
}

h5 {
  font-size: 12px;
  line-height: 26px;
}

h6 {
  font-size: 11px;
  line-height: 24px;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #fefefe;
}

hr.clean {
  margin: 30px 0;
  border: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

a {
  color: red;
}

.title {
  border-bottom: 1px solid #f6f6f6;
  margin: 30px 0px 20px 0px;
}

.title h1,
.title h2,
.title h3,
.title h4,
.title h5,
.title h6 {
  border-bottom: 3px solid red !important;
  margin-bottom: -2px !important;
  display: inline-block !important;
  padding: 0px 10px !important;
}

.title-out h1,
.title-out h2,
.title-out h3,
.title-out h4,
.title-out h5,
.title-out h6 {
  text-shadow: 1px 1px 0px #fff;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 25px;
}

.content {
  padding: 50px;
}

.content .picture {
  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.content.padding20 {
  padding: 20px !important;
}

.content.no-padding {
  padding: 0 !important;
}

.box-s {
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
}

.box {
  /* -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out; */
  text-align: center;
  padding: 10px;
}

.box i {
  /* -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out; */
  font-size: 52px;
  text-align: center;
  margin: 20px 0px 10px 0px;
  color: #414141;
  display: block;
}

.box:hover {
  background: red;
  color: #fff;
}

.box:hover i {
  color: #fff;
}

.box:hover h3 {
  color: #fff;
}

.contact {
  font-size: 14px;
  padding: 15px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 0px #b63001;
  background: url(../../images/orange.png);
  -webkit-box-shadow: 0px 1px 1px rgba(223, 85, 38, .35);
  -moz-box-shadow: 0px 1px 1px rgba(223, 85, 38, .35);
  box-shadow: 0px 1px 1px rgba(223, 85, 38, .35);
}

.contact i {
  margin: -16px 0px;
}

.content .span11 {
  margin-left: 0px;
}

p span.displaynone {
  display: none;
}

/* #Lists
================================================== */

/*ul, ol { margin: 0; }*/

ul {
  list-style: none outside;
}

ol {
  list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
  margin-left: 30px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}


/* Header
=================================================================== */

header {
  margin: 0px 0px 30px 0px;
  height: 100px;
}

.logo {
  height: 120px;
  margin-top: 10px;
}

.logo i {
  margin-top: -25px;
  opacity: .2;
  filter: alpha(opacity=20);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.logo a {
  color: #777;
  text-decoration: none;
  font-size: 28px;
  line-height: 68px;
  margin-left: 10px;
}

.logo span {
  background: red;
  color: #fff;
  padding: 17px 10px 4px 4px;
  margin-left: 5px;
  font-size: 16px;
}

#social-links {
  margin-top: 20px;
  float: right;
}

.dropdown-menu li>a:hover,
.dropdown-menu li>a:focus,
.dropdown-submenu:hover>a {
  color: #ffffff;
  background: red !important;
}

.dropdown-menu .active>a {
  color: #ffffff !important;
  background: red !important;
}

.dropdown-menu .active>a:hover {
  color: #ffffff !important;
  background: red !important;
}

/* Page Title
=================================================================== */

#page-title-icon {
  background: #fff;
  height: 48px;
  width: 48px;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  padding: 4px;
  margin-left: -16px;
  margin-top: -18px;
  position: relative;
  z-index: 10;
  float: left;
}

#page-title {
  height: 100px;
  background: url(../../images/slider-grid2.jpg) repeat;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 20px;
}

#page-title-inner {
  border-top: 5px solid #fcfcfc;
  border-bottom: 5px solid #fcfcfc;
  -webkit-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, .25);
  -moz-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, .25);
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, .25);
  padding: 25px 0px 25px 50px;
}

#page-title i {
  margin: -6px 2px -5px -18px;
  padding: 0px;
}

#page-title h2 {
  font-family: "Droid Sans" !important;
  color: #fff;
  text-shadow: 1px 1px 1px #9e4132;
  font-size: 32px;
}

#page-title span {
  color: #555;
}

/* Images Overlay
=================================================================== */

.picture {
  position: relative;
}

.picture a {
  display: block;
  position: relative;
}

.picture .item-description {
  padding: 5px 15px 5px 15px;
  border: none;
  margin-top: 1px;
  background: #f0eff4;
  font-size: 12px;
  text-align: center;
  /* -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out; */
}

.picture .item-description a {
  color: #414141;
  font-size: 15px;
  /* -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out; */
}

.picture:hover .item-description {
  background: red;
  color: #fff;
}

.picture:hover .item-description a {
  color: #fff;
}

.post .picture {
  position: relative;
  background: #fff;
  padding: 5px;
  border: 1px solid #fcfcfc;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
}

.post .picture:before,
.post .picture:after {
  bottom: 14px;
  content: "";
  position: absolute;
  z-index: -1;
  -ms-transform: rotate(-1deg);
  -webkit-transform: rotate(-1deg);
  /* Safari and Chrome */
  -o-transform: rotate(-1deg);
  /* Opera */
  -moz-transform: rotate(-1deg);
  /* Firefox */
  box-shadow: 0 15px 5px rgba(0, 0, 0, 0.3);
  height: 50px;
  left: 5px;
  max-width: 50%;
  width: 50%;
}

.post .picture:after {
  -ms-transform: rotate(1deg);
  /* IE 9 */
  -webkit-transform: rotate(1deg);
  /* Safari and Chrome */
  -o-transform: rotate(1deg);
  /* Opera */
  -moz-transform: rotate(1deg);
  /* Firefox */
  left: auto;
  right: 5px;
}

#portfolio-wrapper .picture {
  position: relative;
}

#portfolio-wrapper .portfolio-item {
  margin-bottom: 20px;
}

.picture img {
  width: 100%;
  height: auto;
}

.image-overlay-link,
.image-overlay-zoom {
  /* -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out; */
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 40;
  display: none;
  background-color: #222;
  background: rgba(243, 112, 90, 0.6);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.image-overlay-link {
  background-image: url(../../images/overlay-icon-01.png);
}

.image-overlay-zoom {
  background-image: url(../../images/overlay-icon-02.png);
}

.flex-video {
  position: relative;
  /*padding-top: 25px; */
  padding-bottom: 67.5%;
  height: 0;
  overflow: hidden;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
}

.flex-video.widescreen {
  padding-bottom: 57.25%;
}

.flex-video.vimeo {
  padding-top: 0;
}

.flex-video iframe,
.flex-video object,
.flex-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-device-width: 800px),
only screen and (device-width: 1024px) and (device-height: 600px),
only screen and (width: 1280px) and (orientation: landscape),
only screen and (device-width: 800px),
only screen and (max-width: 767px) {
  .flex-video {
    padding-top: 0;
  }
}

/* Flexslider
=================================================================== */

/* Browser Resets
=================================================================== */

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Necessary Styles
=================================================================== */

.flexslider {
  margin: 0;
  padding: 0;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flexslider .slides>li {
  position: relative;
  zoom: 1;
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Suggested container for "Slide" animation setups. Can replace this with your own, if you wish */

.flexslider {
  position: relative;
  zoom: 1;
  z-index: 50;
}

/* Clearfix for the .slides element */

.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */

/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides>li:first-child {
  display: block;
}

/* Default Styles
=================================================================== */

.flexslider {
  background: #fff;
  zoom: 1;
}

.ie7 .flexslider {
  margin-bottom: -30px;
}

.flex-viewport {
  max-height: 2000px;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

/* Direction Nav
=================================================================== */

.flex-direction-nav a {
  width: 35px;
  height: 35px;
  display: block;
  background-color: #555;
  top: 20px;
  right: 20px;
  float: none;
  position: absolute;
  cursor: pointer;
  text-indent: -9999px;
  background-color: #222;
  background: rgba(0, 0, 0, 0.65);
  *bottom: 50px;
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
}

.flex-direction-nav .flex-next {
  background-image: url(../../images/slider-control-right.png);
  background-repeat: no-repeat;
  background-position: 52% 50%;
}

.flex-direction-nav .flex-prev {
  margin-right: 36px;
  background-image: url(../../images/slider-control-left.png);
  background-repeat: no-repeat;
  background-position: 48% 50%;
}

.flex-direction-nav .flex-prev:hover,
.flex-direction-nav .flex-next:hover {
  background-color: red;
  opacity: 1;
}

.subpage .flex-direction-nav a {
  bottom: 0px;
  right: 0px;
  *bottom: 30px;
}

/* Control Nav
=================================================================== */

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -30px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 3px 0 0;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 14px;
  height: 14px;
  display: block;
  background: #c8c8c8;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
  background: #505050;
}

.flex-control-paging li a.flex-active {
  cursor: default;
}

/* Caption
=================================================================== */

.slide-caption {
  width: auto;
  max-width: 30%;
  padding: 10px 20px;
  margin: 0 0 0px 0px;
  position: absolute;
  display: block;
  left: 20px;
  bottom: 20px;
  background-color: #222;
  background: rgba(0, 0, 0, 0.7);
}

.slide-caption.n {
  background-color: #222;
  background: rgba(0, 0, 0, 0.6);
}

.slide-caption h3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  line-height: 33px;
}

.slide-caption p {
  margin: 0;
  line-height: 19px;
  color: #bbb;
}

/* Notice
====================================================================== */

.hero-unit {
  background: #eee;
  border-left: 4px solid red;
  padding: 13px 13px 13px 15px;
  font-style: italic;
  margin: 20px auto;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  font-size: 14px !important;
}

/* Icon Boxes
=================================================================== */

.icons-box-container {
  float: left;
}

.icons-boxes-container {
  float: left;
}

.icons-box {
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.icons-box:hover {
  background: red;
  color: #fff;
}

.icons-box h6 {
  margin: 20px auto 10px auto;
}

.icons-box:hover h6 {
  color: #fff;
  text-shadow: none;
}

.icons-box p {
  float: left;
}

.icons-box i {
  float: left;
  margin: -2px 0 0px 0;
}

.icons-box i.circle {
  float: none !important;
  margin: 0 auto !important;
}

.icons-box i.circle-white {
  float: none !important;
  margin: 0 auto !important;
}

.icons-box i.circle-color {
  float: none !important;
  margin: 0 auto !important;
}

.icons-box i.circle-color-full {
  float: none !important;
  margin: 0 auto !important;
}

.icons-box:hover a {
  text-decoration: none;
}

.circle {
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  margin: 0 auto;
  opacity: .1;
  filter: alpha(opacity=10);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.big {
  border: 20px solid #fff !important;
}

.big-red {
  border: 20px solid red !important;
}

.ico-color {
  background-image: url("../../images/icons/red/glyphicons.png");
  opacity: 1;
}

.mini-color {
  background-image: url("../../images/icons/red/glyphicons-mini.png");
  opacity: 1;
}

.circle-color {
  background-color: #fff;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0px 0px 0px 3px red;
  -moz-box-shadow: 0px 0px 0px 3px red;
  box-shadow: 0px 0px 0px 3px red;
  margin: 0 auto;
  opacity: 1;
}

.circle-white {
  background-color: transparent;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0px 0px 0px 3px #fff;
  -moz-box-shadow: 0px 0px 0px 3px #fff;
  box-shadow: 0px 0px 0px 3px #fff;
  margin: 0 auto;
  opacity: 1;
}

.circle-color-full {
  background-color: red;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0px 0px 0px 3px red;
  -moz-box-shadow: 0px 0px 0px 3px red;
  box-shadow: 0px 0px 0px 3px red;
  margin: 0 auto;
  opacity: 1;
}

/* .big-color {
	border: 20px solid red !important;
} */

/* Icon Boxes Vert
=================================================================== */

.icons-box-vert-container {
  float: left;
}

.icons-boxes-vert-container {
  float: left;
}

.icons-box-vert {
  border: 1px solid #fff;
  padding: 30px 15px 10px 15px;
}

.icons-box-vert:hover {
  background: #fcfcfc;
  border: 1px solid #f6f6f6;
}

.icons-box-vert-info {
  float: right;
  margin-top: -6px;
  margin-bottom: 6px;
  margin-left: 20px;
  width: 70%;
}

.icons-box-vert h3 {
  float: left;
  margin-top: -10px;
}

.icons-box-vert p {
  float: left !important;
}

.icons-box-vert i {
  float: left;
}

.icons-box-vert i.circle {
  float: left !important;
}

.icons-box-vert i.circle {
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 1);
  opacity: .1;
  filter: alpha(opacity=10);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.icons-left {
  float: left;
}

/* About
=================================================================== */

.team-name {
  padding: 15px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
  font-family: "Droid Sans";
  font-size: 18px;
  text-align: center;
}

.team-name span {
  font-size: 12px;
  font-weight: normal;
  color: #888;
}

.team-about p {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
}

/* Shortcodes
====================================================================== */

/* Buttons
=================================================================== */

.button,
input[type="button"] {
  padding: 9px 12px;
  display: inline-block;
  border: 0px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  width: auto;
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
}

input[type="button"] {
  float: left;
}

.button.medium {
  padding: 11px 17px;
  font-size: 14px;
}

.button.gray {
  color: #fff;
  background: #444;
}

.button.gray:hover {
  background: #72b626;
  text-decoration: none
}

.button.light {
  color: #fff;
  background: #aaa;
}

.button.light:hover {
  background: #72b626;
  text-decoration: none
}

.button.color {
  color: #fff;
  background: #72b626;
}

.button.color:hover {
  background: #aaa;
  text-decoration: none
}

/* Input Button */

input[type="button"] {
  color: #fff;
  background: #72b626;
}

input[type="button"]:hover {
  background: #aaa;
  text-decoration: none
}

.button.gray:hover,
.button.light:hover,
.button.color {
  background: #555;
}

.button.color {
  color: #fff;
  background: red;
}

.button.color:hover {
  background: #aaa;
}

input[type="button"] {
  background: red;
  color: #fff;
}

/* Alert Boxes
=================================================================== */

.alert {
  font-family: Manrope;
  line-height: 18px;
  margin-bottom: 15px;
  position: relative;
  padding: 14px 40px 14px 18px;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
}

.alert.alert-success {
  background-color: #edf6e5 !important;
  color: #7a9659 !important;
  border: 1px solid #9fc76f !important;
}

.alert.alert-error {
  background-color: #fdeaea !important;
  color: #ca6f74 !important;
  border: 1px solid #f27b81 !important;
}

.alert {
  background-color: #fffee1 !important;
  color: #daac50 !important;
  border: 1px solid #f5c056 !important;
}

.alert.alert-info {
  background-color: #e9f8ff !important;
  color: #5d9fa9 !important;
  border: 1px solid #75c7d3 !important;
}

/* Testimonial's Carousel
=================================================================== */

.testimonial-container {
  position: relative;
}

.testimonials {
  background: #fff;
  padding: 20px;
  line-height: 21px;
  margin: 0px 1px;
  border-top: 1px solid #dfdfdf;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
}

.testimonials-bg {
  // background: url(../../images/testimonials-bg.png) no-repeat;
  margin-top: -1px;
  display: block;
  width: 200px;
  height: 30px;
}

.testimonials-author {
  color: #666;
  float: left;
  display: block;
  margin-left: 60px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.testimonials-author span {
  color: #a0a0a0
}

/* Carousel */

.carousel-disabled,
.mr-rotato-disabled,
.mr-rotato-next,
.mr-rotato-prev {
  border: 1px solid #f6f6f6;
  display: inline-block;
  margin-right: 1px;
  height: 21px;
  width: 21px;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
  display: block;
  position: absolute;
  margin-top: -40px;
}

.mr-rotato-next {
  // background: url(../../images/testimonials-right-01.png) no-repeat 50%;
  background-color: #fff;
  right: -1px;
  z-index: 6;
}

.mr-rotato-prev {
  // background: url(../../images/testimonials-left-01.png) no-repeat 50%;
  background-color: #fff;
  right: 21px;
  -webkit-box-shadow: 3px 0px 0px 0px #fff;
  box-shadow: 3px 0px 0px 0px #fff;
  z-index: 5;
}

.mr-rotato-next:hover {
  // background: url(../../images/testimonials-right-02.png) no-repeat 50%;
}

.mr-rotato-prev:hover {
  // background: url(../../images/testimonials-left-02.png) no-repeat 50%;
}

.mr-rotato-prev:hover,
.mr-rotato-next:hover {
  background-color: red;
  border-color: red;
}

/* Tabs
=================================================================== */

.tabs-nav {
  list-style: none;
  margin: 0px 0px -36px 0px;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.tabs-nav li {
  float: left;
  line-height: 34px;
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: 0 0 -1px 0;
}

.tabs-nav li a {
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-right: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #888;
  display: block;
  letter-spacing: 0;
  outline: none;
  padding: 0 16px;
  text-decoration: none;
}

.ie7 .tabs-nav li a {
  height: 35px;
}

.tabs-nav li:first-child a {
  border-left: none;
}

.tabs-nav li:last-child a {
  border-right: 1px solid #ddd;
}

.tabs-nav li.active a {
  color: #444;
  font-weight: bold;
}

.tabs-nav li.active a i {
  opacity: 1;
  filter: alpha(opacity=100);
}

.tabs-nav li i {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.tabs-nav li.active:last-child a {
  border-right: 1px solid #fff;
}

.tabs-nav li a:hover {
  background: #fff;
}

.tabs-nav li.active a {
  background: #fff;
  border-bottom: 2px solid #fff;
}

.tabs-container {
  border-top: 1px solid #ddd;
  background: #fff;
  margin: -1px 0 0px;
  overflow: hidden;
  width: 100%;
  line-height: 19px;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
}

.tab-content {
  padding: 60px 20px 20px 20px;
}

/* Progress Bar
=================================================================== */

.progress-bar h5 {
  margin-bottom: 6px;
}

.progress-bar li {
  margin-bottom: 12px;
  list-style: none;
}

.meter {
  height: 23px;
  position: relative;
  background: #fcfcfc;
}

.meter>span {
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.meter>span:after,
.animate>span>span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.meter>span:after,
.animate>span>spans li.current {
  background: red;
}

/* Quotes
=================================================================== */

.quotes {
  background: #fff;
  padding: 3px;
  border: 1px solid #fcfcfc;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
  font-family: "Boogaloo";
}

.quote,
.author {
  line-height: 28px;
  font-size: 24px;
  padding: 15px;
  color: #fff;
  font-family: "Boogaloo";
}

.author {
  font-size: 14px;
  text-align: center;
}

.quote,
.author {
  background: red;
}

/* Dropcaps
=================================================================== */

.dropcap {
  background: #fcfcfc;
  color: #666;
  display: inline-block;
  float: left;
  font-size: 23px;
  font-weight: 600;
  height: 36px;
  line-height: 36px;
  margin: 0 7px 0 0;
  text-align: center;
  width: 36px;
  border: 1px solid #f6f6f6;
}

.dropcap.dark {
  background: #3a3a3a;
  color: #fff;
}

.dropcap.color {
  background: red;
  color: #fff;
}

/* Clients List
=================================================================== */

.clients-carousel {
  background: #fff;
  overflow: hidden;
}

.clients-carousel li {
  float: left;
  overflow: hidden;
  text-align: center;
}


.clients-carousel li img {
  float: none !important;
}

.clients-carousel li:hover {
  background: #fafafa;
}

/* Blog Post
=================================================================== */

/* Comments
=================================================================== */

.comments-sec {
  float: left;
  width: 100%;
  line-height: 20px;
}

ol.commentlist {
  float: left;
  width: 100%;
  margin: 0;
}

ol.commentlist li {
  float: left;
  border-top: 1px solid #e7e7e7;
  padding: 25px 15px 20px 15px;
}

ol.commentlist li:first-child {
  border: none;
  padding: 25px 15px 0px 15px;
}

ol.commentlist li {
  list-style: none;
}

ol li ol.childlist {
  float: right;
  width: 89%;
  margin: 0px;
}

ol.commentlist li ol.childlist li:first-child {
  border-top: 1px solid #e9e9e9;
  padding: 25px 0 0 0;
  font-size: 12px;
}

ol.commentlist li ol.childlist li {
  border-top: 1px solid #e9e9e9;
  margin: 25px 0 15px 0;
  font-size: 12px;
}

.comments {
  float: left;
  width: 100%;
}

.comments-amount {
  color: #888;
}

ol li ol.childlist .comment-des {
  float: right;
  width: 89%;
}

.avatar {
  float: left
}

.comment-des {
  float: right;
  width: 90%;
}

.comment-des strong {
  float: left;
  padding-right: 5px;
  font-size: 12px;
}

.comment-des span {
  float: left;
  color: #888;
}

.comment-by {
  float: left;
  width: 100%;
  padding-bottom: 8px;
  padding-top: 5px;
  color: #000;
}

.ie7 .comment-by span.reply span {
  display: none;
}

.comment-by span.reply {
  color: #888;
  float: right;
  display: inline;
}

.comment-by span.reply a {
  float: right;
  height: 17px;
  margin-left: 5px;
  font-weight: normal;
  float: right;
}

.comment-by span.date {
  color: #888;
  padding-right: 7px;
  float: right;
}

.ie7 .comment-by .date {
  display: none;
}

/* Contact Form
=================================================================== */

.field textarea {
  max-width: 97%;
  min-width: 97%;
  height: 130px;
  margin: 5px 0 15px 0;
}

.field input {
  width: 240px;
  margin-bottom: 15px;
}

.field:focus {
  border: 1px solid #eee;
}

.field label span {
  color: #666;
}

.field .validation-error,
.field .validation-error:focus {
  border: 1px solid #f2c4c2;
  background: #ffeceb;
}

.loading {
  background: url(../../images/loading.gif) no-repeat 1px;
  height: 28px;
  width: 28px;
  float: left;
  margin: 5px 0 0 10px;
  display: none;
}

.success-message .notification {
  margin-bottom: 20px;
}

.success-message {
  display: none;
}

.form-spacer {
  margin-top: 20px;
}

/* Pricing Tables
=================================================================== */

.five-tables .pricing-table {
  width: 100%;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.four-tables .pricing-table {
  width: 100%;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.three-tables .pricing-table {
  width: 100%;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.two-tables .pricing-table {
  width: 100%;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.pricing-table {
  float: left;
  border: 5px solid #fff;
}

.pricing-table h3 {
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 6px 0;
  margin: 0;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}

.pricing-table li {
  color: #888;
  background: #fff;
  text-align: center;
  padding: 11px 0;
  border-bottom: 1px solid #fff;
}

.pricing-table li:nth-child(2n) {
  background: #f6f6f6;
}

.pricing-table h4 {
  padding: 0 0 10px;
}

a.buy {
  text-align: center;
  display: block;
  color: #fff;
  padding: 12px 0;
  font-weight: bold;
  font-size: 14px;
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px;
}

a.buy:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

/* News ticker
=================================================================== */
.glyphicon {
  margin-right: 4px !important;
  /*override*/
}

/* .pagination .glyphicon
{
    margin-right:0px !important;
} */

.mini-color {
  margin-right: 4px !important;
  margin-top: 2px;
}

.pagination .mini-color {
  margin-right: 0px !important;
}

/*.pagination a
{
    color:#555;
} */

.news-item {
  padding: 4px 4px;
  margin: 0px;
  margin-left: -40px;
  border-bottom: 1px dotted #555;
}

/* Panel bootstrap 3
=================================================================== */

#calendar {
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

/* Table
=================================================================== */

.pricing-table h3,
.pricing-table h4 {
  color: #fff;
  margin: 0;
}

.pricing-table h4 {
  padding: 15px 0;
}

.price,
.time {
  display: block;
  text-align: center;
  line-height: 24px;
}

.price {
  font-size: 24px;
  font-weight: normal;
}

.priceOther {
  font-size: 18px;
  font-weight: normal;
}

.time {
  font-size: 12px;
  font-weight: normal;
}

/* Color Schemes
=================================================================== */

/* Color - 1 */

.pricing-table .color-cccddd h3,
.color-cccddd .buy {
  background-color: #ccc;
}

.pricing-table .color-cccddd h4 {
  background-color: #ddd;
}

/* Color - 2 */

.pricing-table .color-666777 h3,
.color-666777 .buy {
  background-color: #666;
}

.pricing-table .color-666777 h4 {
  background-color: #777;
}

/* Color - 3 */

.pricing-table .color-custom h3,
.color-custom .buy {
  background-color: #111;
  padding: 15px 0;
}

.pricing-table .color-custom h4 {
  background-color: #222;
  padding: 35px 0;
}

.color-custom {
  margin-top: -57px;
}

.pricing-table {
  position: relative !important;
  z-index: 5 !important;
  /* margin: 60px 0px; */
}

.pricing-table.best-option {
  margin-top: 2px;
  padding-top: 57px;
  position: relative !important;
  z-index: 6 !important;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, .55);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, .55);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .55);
}

.pricing-table .color-custom h3,
.color-custom .buy {
  background-color: #df6652;
  font-size: 20px
}

.pricing-table .color-custom h4 {
  background-color: red;
}

/* Google Map
=================================================================== */

#googlemaps:before,
#googlemaps:after {
  bottom: 14px;
  content: "";
  position: absolute;
  z-index: -1;
  -ms-transform: rotate(-1deg);
  -webkit-transform: rotate(-1deg);
  /* Safari and Chrome */
  -o-transform: rotate(-1deg);
  /* Opera */
  -moz-transform: rotate(-1deg);
  /* Firefox */
  box-shadow: 0 15px 5px rgba(0, 0, 0, 0.3);
  height: 50px;
  left: 5px;
  max-width: 50%;
  width: 50%;
}

#googlemaps:after {
  -ms-transform: rotate(1deg);
  /* IE 9 */
  -webkit-transform: rotate(1deg);
  /* Safari and Chrome */
  -o-transform: rotate(1deg);
  /* Opera */
  -moz-transform: rotate(1deg);
  /* Firefox */
  left: auto;
  right: 5px;
}

#googlemaps {
  position: relative;
  margin-top: 0px;
  height: 400px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 2px;
  background: #fcfcfc;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .35);
}

#map {
  height: 400px;
  width: 100%;
}

/* Filters
=================================================================== */

#filters {
  text-align: center;
  right: 0;
  margin: 0px 0px 10px 0px;
  font-size: 16px;
  text-shadow: 0px 1px 1px #fff;
  color: #666;
  position: relative;
  z-index: 3;
}

#filters ul li {
  display: inline;
}

#filters a {
  padding: 5px 10px;
  display: inline-block;
  color: #666;
  margin: 0 5px 10px 0;
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
}

#filters a:hover,
.selected {
  color: red !important;
  border-bottom: 1px solid red !important;
  text-decoration: none;
}

/* Posts
=================================================================== */

.post {
  padding: 10px;
  margin-bottom: 10px;
}

.post.post-page {
  border-bottom: none;
  margin-bottom: 0;
}

.post-content {
  padding: 10px 10px 20px 10px;
  margin: 22px 0 0 0px;
  border-bottom: 1px solid #eee;
}

.post .info {
  width: 100px;
  float: left;
  margin-top: 30px;
}

.post .post-date {
  display: block;
  background: red;
  color: #fff;
  width: 80px;
  height: 40px;
  text-align: center;
  padding: 10px 0px;
  margin: 10px 0px;
}

.post .post-date .day {
  display: block;
  background: red;
  font-size: 20px;
}

.post .post-date .month-year {
  display: block;
  background: red;
  font-size: 10px;
}

.post .post-icon {
  display: block;
  width: 80px;
  height: 60px;
  background: #eee;
  font-size: 40px;
  line-height: 65px;
  color: #fff;
  text-align: center;
}

.post .post-meta {
  color: #666;
  margin: -10px 0px 0px 90px;
  padding: 10px 0px;
}

.post .post-meta a {
  text-decoration: none;
}

.post .post-meta i {
  opacity: 0.75;
  filter: alpha(opacity=75);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  margin-right: 5px;
  zoom: 1;
}

.post .post-meta span {
  margin-right: 10px;
}

#portfolio-wrapper .post-meta {
  background: #f0eff4;
  color: #666;
  padding: 10px 15px;
  border-top: 2px solid #f6f6f6;
}

#portfolio-wrapper .post-meta a {
  text-decoration: none;
}

#portfolio-wrapper .post-meta i {
  opacity: 0.35;
  filter: alpha(opacity=35);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  margin-right: 5px;
  zoom: 1;
}

#portfolio-wrapper .post-meta span {
  margin-right: 10px;
}

/* Portfolio Info
=================================================================== */

.project-info {
  margin-bottom: 15px;
}

.project-info .button {
  margin-bottom: 30px !important;
}

.project-info strong {
  color: red;
}

.launch {
  margin-bottom: 30px;
}

.project-tags {
  margin-top: 15px;
}

.project-tags strong {
  float: left;
  margin: 5px 10px 20px 0;
}

/* Pagination
=================================================================== */
/*.pagination {
	margin-left: 30px;
}

.pagination li {
	padding: 5px 10px;
	display: inline-block;
	*float: left;
	color: #888;
	margin-right: 1px;
	background: #fff;
	border: 1px solid #fff;
}

.pagination li:hover {
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, .25);
  -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, .25);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .25);
}

.ie7 .pagination li {margin-right: 5px;} */

li.current {
  background: red;
  border: 1px solid red;
  color: #fff;
  /* -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out; */
}

/* Widgets
=================================================================== */

.widget {
  padding: 10px;
  margin-top: 0px;
}

.widget.first {
  margin-top: -30px;
}

/* Tags */

.tags a {
  padding: 5px 10px;
  border: 1px solid #eee;
  display: inline-block;
  color: #666;
  background: #fff;
  margin: 0 3px 6px 0;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
  border-radius: 1px;
}

.tags a:last-child {
  margin-right: 0;
}

.tags a:hover {
  background: red;
  border: 1px solid red;
  color: #fff;
}

/* Isotope Filtering
=================================================================== */

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/* Isotope CSS3 transitions
=================================================================== */

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, left, opacity;
  transition-property: transform, opacity;
}

/* Disabling Isotope CSS3 transitions
=================================================================== */

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

/* Disable CSS transitions for containers with infinite scrolling
====================================*/

.isotope.infinite-scrolling {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

/* List Styles
=================================================================== */

.check_list li,
.plus_list li,
.minus_list li,
.star_list li,
.arrow_list li,
.square_list li,
.circle_list li,
.cross_list li {
  list-style: none;
  margin: 5px 0;
}

.check_list li {
  background: url(../../images/icons/list/red/list_check.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.plus_list li {
  background: url(../../images/icons/list/red/list_plus.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.minus_list li {
  background: url(../../images/icons/list/red/list_minus.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.star_list li {
  background: url(../../images/icons/list/red/list_star.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.arrow_list li {
  background: url(../../images/icons/list/red/list_arrow.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.square_list li {
  background: url(../../images/icons/list/red/list_square.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.circle_list li {
  background: url(../../images/icons/list/red/list_circle.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.cross_list li {
  background: url(../../images/icons/list/red/list_cross.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

/* Footer
====================================================================== */

#footer {
  margin-top: 20px;
  background: #444c4e;
  padding: 40px 0px;
  border-top: 10px solid red;
  height: 400px;
}

#footer,
#footer p,
#footer a {
  color: #7b7f80 !important;
}

#footer p i {
  color: #dbe1e1;
  font-size: 15px;
  display: inline-block;
  width: 15px;
  text-align: center
}

#footer a:hover {
  color: #fff;
}

.no-margin {
  margin-top: 0;
}

#footer h3 {
  padding: 2px 0px !important;
  margin-bottom: 10px;
  color: #dbe1e1;
}

#under-footer {
  height: 50px;
  background: #373f41;
  color: #7b8181;
}

#under-footer-logo {
  height: 46px;
}

#under-footer-logo a {
  color: #dbe1e1;
  text-decoration: none;
  font-size: 22px;
  line-height: 48px;
  margin-left: 5px;
}

#under-footer-logo span {
  color: #7b8181;
  font-size: 17px;
}

#under-footer-copyright {
  margin: 17px 0px 0px 0px;
}

#under-footer-copyright a {
  color: #777;
}

#under-footer-back-to-top {
  margin: 10px;
  z-index: 999;
}

#under-footer-back-to-top .btn {
  width: 30px;
  height: 30px;
  display: block;
  background-color: red;
  background-image: url(../../images/scroll-top-top.png);
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#under-footer-back-to-top .btn:hover {
  background-color: #aaa;
}

/* Links List
=================================================================== */

.links-list-alt li a {
  font-size: 14px;
  color: #666;
  display: block;
  padding: 6px 12px;
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  margin: 5px 0px;
}

.links-list li a:hover {
  color: #efe9d8
}

.links-list-alt li a:hover {
  color: #888;
  background: #fcfcfc;
  border: 1px solid #f6f6f6;
  text-decoration: none;
}

.links-list li:first-child a,
.links-list-alt li:first-child a {
  margin: -3px 0 0 0;
}

/* Photo Stream
=================================================================== */

.flickr-widget {
  margin-top: 15px;
  padding: 0px 5px;
  overflow: hidden;
}

.flickr-widget img {
  display: block;
  width: 100%;
}

.flickr-widget a:hover {
  border-color: red;
}

.flickr-widget a {
  float: left;
  width: 50px;
  height: 50px;
  margin-left: 0px;
  margin-right: 9px;
  margin-bottom: 9px;
  background: #0a0c0e;
  border: 1px solid #0a0c0e;
  /* -webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; */
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
}

.ie7 .flickr-widget a {
  width: 54px;
  height: 54px;
}

/* Twitter Widget
=================================================================== */

#twitter {
  font-size: 12px;
  float: left;
  display: block;
}

#twitter b a,
#twitter b a:hover {
  color: #666;
  font-weight: bold
}

#twitter li {
  // background: url(../../images/icons/twitter.png) no-repeat 0% 50%;
  padding-left: 40px;
  line-height: 18px;
  margin: 2px 0 12px 0;
}

#twitter li:last-child {
  padding-left: 40px;
  margin: 0;
}

/* Custom Buttons Colors
====================================================================== */

.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}

.btn {
  border-color: #c5c5c5;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}

.btn-primary {
  background-color: red;
  color: #ffffff;
  background-image: -moz-linear-gradient(top, red, red);
  background-image: -ms-linear-gradient(top, red, red);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(red), to(red));
  background-image: -webkit-linear-gradient(top, red, red);
  background-image: -o-linear-gradient(top, red, red);
  background-image: linear-gradient(top, red, red);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#'f3705a, endColorstr='#'f3705a, GradientType=0);
  border-color: red red red;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  background-color: red;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #003399 \9;
}

.btn-warning {
  background-color: #faa732;
  color: #ffffff;
  background-image: -moz-linear-gradient(top, #fbb450, #fbb450);
  background-image: -ms-linear-gradient(top, #fbb450, #fbb450);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#fbb450));
  background-image: -webkit-linear-gradient(top, #fbb450, #fbb450);
  background-image: -o-linear-gradient(top, #fbb450, #fbb450);
  background-image: linear-gradient(top, #fbb450, #fbb450);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#'fbb450, endColorstr='#'fbb450, GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  background-color: #fbb450;
}

.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}

.btn-danger {
  background-color: #da4f49;
  color: #ffffff;
  background-image: -moz-linear-gradient(top, #ee5f5b, #ee5f5b);
  background-image: -ms-linear-gradient(top, #ee5f5b, #ee5f5b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#ee5f5b));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee5f5b);
  background-image: -o-linear-gradient(top, #ee5f5b, #ee5f5b);
  background-image: linear-gradient(top, #ee5f5b, #ee5f5b);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#'ee5f5b, endColorstr='#'ee5f5b, GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  background-color: #ee5f5b
}

.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}

.btn-success {
  background-color: #5bb75b;
  color: #ffffff;
  background-image: -moz-linear-gradient(top, red, red);
  background-image: -ms-linear-gradient(top, red, red);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(red), to(red));
  background-image: -webkit-linear-gradient(top, red, red);
  background-image: -o-linear-gradient(top, red, red);
  background-image: linear-gradient(top, red, red);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#'f3705a, endColorstr='#'f3705a, GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  background-color: red;
}

.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}

.btn-info {
  background-color: #49afcd;
  color: #ffffff;
  background-image: -moz-linear-gradient(top, #5bc0de, #5bc0de);
  background-image: -ms-linear-gradient(top, #5bc0de, #5bc0de);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#5bc0de));
  background-image: -webkit-linear-gradient(top, #5bc0de, #5bc0de);
  background-image: -o-linear-gradient(top, #5bc0de, #5bc0de);
  background-image: linear-gradient(top, #5bc0de, #5bc0de);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#'5bc0de, endColorstr='#'5bc0de, GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  background-color: #5bc0de;
}

.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}

.btn-inverse {
  color: #ffffff;
  background-color: #363636;
  *background-color: #222222;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
  background-image: -webkit-linear-gradient(top, #444444, #222222);
  background-image: -o-linear-gradient(top, #444444, #222222);
  background-image: linear-gradient(to bottom, #444444, #222222);
  background-image: -moz-linear-gradient(top, #444444, #222222);
  background-repeat: repeat-x;
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
  *background-color: #151515;
}

.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}

/* Higher than 960 (desktop devices)
====================================================================== */

@media (min-width: 1200px) {}

/* Higher than 960 (desktop devices)
====================================================================== */

@media only screen and (min-width: 980px) and (max-width: 1199px) {
  .content {
    padding: 40px;
  }

  .navbar .nav>li>a {
    padding: 28px 15px;
  }

  .flexslider.home {
    min-height: 300px;
  }
}

/* Tablet Portrait (devices and browsers)
====================================================================== */

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .navbar .nav>li>a {
    color: #666;
    border-top: 4px solid transparent;
    text-shadow: none;
    font-size: 14px;
    padding: 2px 40px;
    /* -webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out; */
  }

  .content {
    padding: 30px !important;
  }

  ol li ol.childlist .comment-des {
    width: 86%;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 15px 5px 50px;
  }
}

/* All Mobile Sizes (devices and browser)
====================================================================== */

@media only screen and (max-width: 767px) {
  .navbar .nav>li>a {
    color: #666;
    border-top: 4px solid transparent;
    text-shadow: none;
    font-size: 14px;
    padding: 2px 40px;
    /* -webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out; */
  }

  .content {
    padding: 10px !important;
  }

  #contact-details {
    text-align: center;
    float: left;
  }

  .post-title h2 {
    line-height: 26px;
    margin-bottom: 15px;
  }

  .post-icon {
    margin-top: 28px;
  }

  #filters,
  #portfolio-navi {
    position: relative;
    text-align: left;
    display: block;
    margin: 0 0 23px 0;
  }

  #portfolio-navi {
    margin: -5px 0 25px 0;
  }

  .portfolio-item {
    margin: 5px 0 25px 0 !important;
  }

  .item-description.related {
    margin-bottom: 30px;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 15px 5px 50px;
  }
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers)
====================================================================== */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  // body {
  //   padding: 0px;
  // }

  body>#wrapper {
    padding-top: 60px;
  }

  #wrapper {
    padding: 0px 10px;
  }

  #footer {
    padding: 0px 10px;
  }

  #under-footer {
    padding: 0px 10px;
  }

  .flickr-widget a {
    width: 53px;
    height: 53px;
  }

  ol li ol.childlist .comment-des {
    width: 82%;
  }

  .comment-des {
    width: 83%;
  }

  .flexslider.home {
    display: none;
  }

  .logo {
    display: none;
  }
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers)
=================================================================== */

@media only screen and (max-width: 479px) {
  // body {
  //   padding: 0px;
  // }

  body>#wrapper {
    padding-top: 60px;
  }

  #wrapper {
    padding: 0px 10px;
  }

  #footer {
    padding: 0px 10px;
  }

  #under-footer {
    padding: 0px 10px;
  }

  ol li ol.childlist .comment-des {
    width: 77%;
  }

  .comment-des {
    width: 78%;
  }

  .flexslider.home {
    display: none;
  }

  .logo {
    display: none;
  }
}