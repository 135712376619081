/* Social Icons - small
=================================================================== */

#social a {
	width: 28px;
	height: 28px;
	margin: 0 6px 6px 0;
	display: block;
	float: left;
	text-indent: -9999px;
	background-position: 0 0px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#social a:hover {
	width: 28px;
	height: 28px;
	margin: 0 6px 6px 0;
	display: block;
	float: left;
	text-indent: -9999px;
	background-position: 0 -38px;
}

#social a img{
	border: none;
}

#social-r a {
	width: 28px;
	height: 28px;
	margin: 0 6px 6px 0;
	display: block;
	float: left;
	text-indent: -9999px;
	background-position: 0 -38px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#social-r a:hover {
	width: 28px;
	height: 28px;
	margin: 0 6px 6px 0;
	display: block;
	float: left;
	text-indent: -9999px;
	background-position: 0 0px;
}

#social-r a img{
	border: none;
}

.facebook {background: url(../../images/icons/social/facebook.png) no-repeat;}

/* Social Icons - big
=================================================================== */

.social-bookmarks {
	padding: 0!important;
	margin: 0!important;
	list-style-type: none!important;
}

.social-bookmarks li{
	float: left!important;
	padding: 0px!important;
	display: inline!important;
	width: auto!important;
	background: none;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}			

.social-bookmarks li a {
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	display: block!important;
	float: left!important;
	height: 100%!important;
	margin: 1px!important;
	padding: 0!important;
	width: 40px!important;
	height: 40px!important;
	text-indent: -9999px!important;
}

.social-bookmarks li:last-child  a{

}
.social-bookmarks li a:hover {
	background-position: 0 -40px!important;
	
}
.social-bookmarks li a {
	background-color:transparent;
}

.social-bookmarks li.facebook a {
	background: url(../../images/icons/social2/facebook.png) no-repeat 0 0;
}
.social-bookmarks li.facebook a:hover {
	background-color: #3b5998;
}

/* Social Icons - circle big
=================================================================== */
.social-grid {
	margin: 0px 0 0 0;
	padding: 0;
	list-style: none;
	display: block;
	text-align: center;
	width: 100%;
}

.social-grid li {
	width: 72px;
	height: 72px;
	display: inline-block;
	margin: 6px 12px;
}

.social-grid li a{
	width: 72px;
	height: 72px;
	display:block;
}


.social-item {
	width: 72px;
	height: 72px;
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;
	position: relative;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	cursor: default;
}

.social-info-wrap{
	position: absolute;
	width: 72px;
	height: 72px;
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;

	-webkit-perspective: 800px;
	-moz-perspective: 800px;
	-o-perspective: 800px;
	-ms-perspective: 800px;
	perspective: 800px;

	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

	box-shadow: 
		0 0 0 5px #f6f6f6,
		inset 0 0 3px rgba(115,114, 23, 0.8);

}

.social-info{
	position: absolute;
	width: 70px;
	height: 70px;	
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
		
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;

}

.social-info > div {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-position: center center;
	border: 1px solid #999;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}

.social-info .social-info-back {
	-webkit-transform: rotate3d(0,1,0,180deg);
	-moz-transform: rotate3d(0,1,0,180deg);
	-o-transform: rotate3d(0,1,0,180deg);
	-ms-transform: rotate3d(0,1,0,180deg);
	transform: rotate3d(0,1,0,180deg);
	margin-left: -2px;
}

.social-item:hover .social-info-wrap {
	box-shadow: 
		0 0 0 0 rgba(0,0,0,0.8), 
		inset 0 0 3px rgba(115,114, 23, 0.8);
}

.social-item:hover .social-info {
	-webkit-transform: rotate3d(0,1,0,-180deg);
	-moz-transform: rotate3d(0,1,0,-180deg);
	-o-transform: rotate3d(0,1,0,-180deg);
	-ms-transform: rotate3d(0,1,0,-180deg);
	transform: rotate3d(0,1,0,-180deg);
}

.social-facebook { 
	background: #f6f6f6 url(../../images/icons/social_big/facebook_grey.png) no-repeat;
}

.social-facebook-hover { 
	background: #3b5998 url(../../images/icons/social_big/facebook_white.png) no-repeat;
}

/* Social Icons - circle small
=================================================================== */
.social-small-grid {
	margin: 0px 0 0 0;
	padding: 0;
	list-style: none;
	display: block;
	text-align: center;
	width: 100%;
}

.social-small-grid li {
	width: 32px;
	height: 32px;
	display: inline-block;
	margin: 6px 6px;
}

.social-small-grid li a{
	width: 32px;
	height: 32px;
	display:block;
}


.social-small-item {
	width: 32px;
	height: 32px;
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;
	position: relative;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	cursor: default;
}

.social-small-info-wrap{
	position: absolute;
	width: 32px;
	height: 32px;
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;

	-webkit-perspective: 800px;
	-moz-perspective: 800px;
	-o-perspective: 800px;
	-ms-perspective: 800px;
	perspective: 800px;

	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

	box-shadow: 
		0 0 0 3px #f6f6f6,
		inset 0 0 3px rgba(246,246, 246, 1);

}

.social-small-info{
	position: absolute;
	width: 30px;
	height: 30px;	
	-webkit-border-radius: 50em;
	-moz-border-radius: 50em;
	border-radius: 50em;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
		
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;

}

.social-small-info > div {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-position: center center;
	border: 1px solid #fff;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}

.social-small-info .social-small-info-back {
	-webkit-transform: rotate3d(0,1,0,180deg);
	-moz-transform: rotate3d(0,1,0,180deg);
	-o-transform: rotate3d(0,1,0,180deg);
	-ms-transform: rotate3d(0,1,0,180deg);
	transform: rotate3d(0,1,0,180deg);
	margin-left: -2px;
}

.social-small-item:hover .social-small-info-wrap {
	box-shadow: 
		0 0 0 0 rgba(255,255,255,0.8), 
		inset 0 0 3px rgba(246,246, 246, 1);
}

.social-small-item:hover .social-small-info {
	-webkit-transform: rotate3d(0,1,0,-180deg);
	-moz-transform: rotate3d(0,1,0,-180deg);
	-o-transform: rotate3d(0,1,0,-180deg);
	-ms-transform: rotate3d(0,1,0,-180deg);
	transform: rotate3d(0,1,0,-180deg);
}

.social-small-facebook { 
	background: #ffffff url(../../images/icons/social_small/facebook_grey.png) no-repeat;
}

.social-small-facebook-hover { 
	background: #3b5998 url(../../images/icons/social_small/facebook_white.png) no-repeat;
}
