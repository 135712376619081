/* List styles
=================================================================== */

.check_list li {
  background: url(../../images/icons/list/red/list_check.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.plus_list li {
  background: url(../../images/icons/list/red/list_plus.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.minus_list li {
  background: url(../../images/icons/list/red/list_minus.png) no-repeat 0% 50%;
  padding: 0 0 0 18px;
}

.star_list li {
  background: url(../../images/icons/list/red/list_star.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.arrow_list li {
  background: url(../../images/icons/list/red/list_arrow.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.square_list li {
  background: url(../../images/icons/list/red/list_square.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.circle_list li {
  background: url(../../images/icons/list/red/list_circle.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.cross_list li {
  background: url(../../images/icons/list/red/list_cross.png) no-repeat 0% 50%;
  padding: 0 0 0 16px;
}

.project-info strong {
  color: #89c236;
}


/* Icons Box
=================================================================== */

.ico-color {
  background-image: url("../../images/icons/red/glyphicons.png");
  opacity: 1;
}

.mini-color {
  background-image: url("../../images/icons/red/glyphicons-mini.png");
  opacity: 1;
}

/* Icons starter webpack
=================================================================== */

@font-face {
  font-family: 'GlyphIcons';
  src:
    url('Fonts/icons/GlyphIcons/glyphicons-halflings-regular.svg') format('svg'),
    url('Fonts/icons/GlyphIcons/glyphicons-halflings-regular.woff2') format('woff2');
  font-style: normal;
}

.glyphicon {
  &::before {
    font-family: 'Glyphicons';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon {
  max-width: 32px;
  max-height: 32px;
}

.webicon {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: contain;
}

// icons with size < 4 KB as data-URL

.webicon-facebook {
  background-image: url('Images/icons/webicons/webicon-facebook.svg');
}