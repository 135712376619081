.navbar .navbar-nav .nav-link:hover {
  background-color: red;
}

.navbar .navbar-nav .nav-link {
  padding: 0.6em;
  font-size: 1em;
  transition: all 0.5s;
}

.navbar .navbar-brand {
  padding: 0 0.6em;
  font-size: 1.5em;
  font-weight: bold;
}

@media only screen and (min-width: 992px) {
  .navbar {
    padding: 0;
  }

  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }

  .navbar .navbar-brand {
    padding: 0 0.8em;
  }
}